<template>
    <ub-color size="mini" type="color-1">1</ub-color>
    <ub-color size="small" type="color-1">1</ub-color>
    <ub-color size="middle" type="color-1">1</ub-color>
    <ub-color size="large" type="color-1">1</ub-color>
    <br><br>
    <pre>{{ hhh11 }}</pre>
    <pre>{{ hhh12 }}</pre>
    <pre>{{ hhh13 }}</pre>
    <pre>{{ hhh14 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-2">2</ub-color>
    <ub-color size="small" type="color-2">2</ub-color>
    <ub-color size="middle" type="color-2">2</ub-color>
    <ub-color size="large" type="color-2">2</ub-color>
    <br><br>
    <pre>{{ hhh21 }}</pre>
    <pre>{{ hhh22 }}</pre>
    <pre>{{ hhh23 }}</pre>
    <pre>{{ hhh24 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-3">3</ub-color>
    <ub-color size="small" type="color-3">3</ub-color>
    <ub-color size="middle" type="color-3">3</ub-color>
    <ub-color size="large" type="color-3">3</ub-color>
    <br><br>
    <pre>{{ hhh31 }}</pre>
    <pre>{{ hhh32 }}</pre>
    <pre>{{ hhh33 }}</pre>
    <pre>{{ hhh34 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-4">4</ub-color>
    <ub-color size="small" type="color-4">4</ub-color>
    <ub-color size="middle" type="color-4">4</ub-color>
    <ub-color size="large" type="color-4">4</ub-color>
    <br><br>
    <pre>{{ hhh41 }}</pre>
    <pre>{{ hhh42 }}</pre>
    <pre>{{ hhh43 }}</pre>
    <pre>{{ hhh44 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-5">5</ub-color>
    <ub-color size="small" type="color-5">5</ub-color>
    <ub-color size="middle" type="color-5">5</ub-color>
    <ub-color size="large" type="color-5">5</ub-color>
    <br><br>
    <pre>{{ hhh51 }}</pre>
    <pre>{{ hhh52 }}</pre>
    <pre>{{ hhh53 }}</pre>
    <pre>{{ hhh54 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-6">6</ub-color>
    <ub-color size="small" type="color-6">6</ub-color>
    <ub-color size="middle" type="color-6">6</ub-color>
    <ub-color size="large" type="color-6">6</ub-color>
    <br><br>
    <pre>{{ hhh61 }}</pre>
    <pre>{{ hhh62 }}</pre>
    <pre>{{ hhh63 }}</pre>
    <pre>{{ hhh64 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-7">7</ub-color>
    <ub-color size="small" type="color-7">7</ub-color>
    <ub-color size="middle" type="color-7">7</ub-color>
    <ub-color size="large" type="color-7">7</ub-color>
    <br><br>
    <pre>{{ hhh71 }}</pre>
    <pre>{{ hhh72 }}</pre>
    <pre>{{ hhh73 }}</pre>
    <pre>{{ hhh74 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-8">8</ub-color>
    <ub-color size="small" type="color-8">8</ub-color>
    <ub-color size="middle" type="color-8">8</ub-color>
    <ub-color size="large" type="color-8">8</ub-color>
    <br><br>
    <pre>{{ hhh81 }}</pre>
    <pre>{{ hhh82 }}</pre>
    <pre>{{ hhh83 }}</pre>
    <pre>{{ hhh84 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-9">9</ub-color>
    <ub-color size="small" type="color-9">9</ub-color>
    <ub-color size="middle" type="color-9">9</ub-color>
    <ub-color size="large" type="color-9">9</ub-color>
    <br><br>
    <pre>{{ hhh91 }}</pre>
    <pre>{{ hhh92 }}</pre>
    <pre>{{ hhh93 }}</pre>
    <pre>{{ hhh94 }}</pre>

    <div class="line"></div>
    <ub-color size="mini" type="color-10">10</ub-color>
    <ub-color size="small" type="color-10">10</ub-color>
    <ub-color size="middle" type="color-10">10</ub-color>
    <ub-color size="large" type="color-10">10</ub-color>
    <br><br>
    <pre>{{ hhh101 }}</pre>
    <pre>{{ hhh102 }}</pre>
    <pre>{{ hhh103 }}</pre>
    <pre>{{ hhh104 }}</pre>
    
    <div class="line"></div>
    <ub-color size="mini" type="color-11">11</ub-color>
    <ub-color size="small" type="color-11">11</ub-color>
    <ub-color size="middle" type="color-11">11</ub-color>
    <ub-color size="large" type="color-11">11</ub-color>
    <br><br>
    <pre>{{ hhh111 }}</pre>
    <pre>{{ hhh112 }}</pre>
    <pre>{{ hhh113 }}</pre>
    <pre>{{ hhh114 }}</pre>

    <br><br>
    <i>the end.</i>
    <br><br><br><br><br>
</template>

<script>
export default {
    name: "ColorShow",
    data() {
        return {
            hhh11:`<ub-color size="mini" type="color-1">1</ub-color>`,
            hhh12:`<ub-color size="small" type="color-1">1</ub-color>`,
            hhh13:`<ub-color size="middle" type="color-1">1</ub-color>`,
            hhh14:`<ub-color size="large" type="color-1">1</ub-color>`,

            hhh21:`<ub-color size="mini" type="color-2">2</ub-color>`,
            hhh22:`<ub-color size="small" type="color-2">2</ub-color>`,
            hhh23:`<ub-color size="middle" type="color-2">2</ub-color>`,
            hhh24:`<ub-color size="large" type="color-2">2</ub-color>`,

            hhh31:`<ub-color size="mini" type="color-3">3</ub-color>`,
            hhh32:`<ub-color size="small" type="color-3">3</ub-color>`,
            hhh33:`<ub-color size="middle" type="color-3">3</ub-color>`,
            hhh34: `<ub-color size="large" type="color-3">3</ub-color>`,

            hhh41: `<ub-color size="mini" type="color-4">4</ub-color>`,
            hhh42: `<ub-color size="small" type="color-4">4</ub-color>`,
            hhh43: `<ub-color size="middle" type="color-4">4</ub-color>`,
            hhh44: `<ub-color size="large" type="color-4">4</ub-color>`,

            hhh51: `<ub-color size="mini" type="color-5">5</ub-color>`,
            hhh52: `<ub-color size="small" type="color-5">5</ub-color>`,
            hhh53: `<ub-color size="middle" type="color-5">5</ub-color>`,
            hhh54: `<ub-color size="large" type="color-5">5</ub-color>`,

            hhh61: `<ub-color size="mini" type="color-6">6</ub-color>`,
            hhh62: `<ub-color size="small" type="color-6">6</ub-color>`,
            hhh63: `<ub-color size="middle" type="color-6">6</ub-color>`,
            hhh64: `<ub-color size="large" type="color-6">6</ub-color>`,

            hhh71: `<ub-color size="mini" type="color-7">7</ub-color>`,
            hhh72: `<ub-color size="small" type="color-7">7</ub-color>`,
            hhh73: `<ub-color size="middle" type="color-7">7</ub-color>`,
            hhh74: `<ub-color size="large" type="color-7">7</ub-color>`,

            hhh81: `<ub-color size="mini" type="color-8">8</ub-color>`,
            hhh82: `<ub-color size="small" type="color-8">8</ub-color>`,
            hhh83: `<ub-color size="middle" type="color-8">8</ub-color>`,
            hhh84: `<ub-color size="large" type="color-8">8</ub-color>`,

            hhh91: `<ub-color size="mini" type="color-9">9</ub-color>`,
            hhh92: `<ub-color size="small" type="color-9">9</ub-color>`,
            hhh93: `<ub-color size="middle" type="color-9">9</ub-color>`,
            hhh94: `<ub-color size="large" type="color-9">9</ub-color>`,

            hhh101: `<ub-color size="mini" type="color-10">10</ub-color>`,
            hhh102: `<ub-color size="small" type="color-10">10</ub-color>`,
            hhh103: `<ub-color size="middle" type="color-10">10</ub-color>`,
            hhh104: `<ub-color size="large" type="color-10">10</ub-color>`,

            hhh111: `<ub-color size="mini" type="color-11">11</ub-color>`,
            hhh112: `<ub-color size="small" type="color-11">11</ub-color>`,
            hhh113: `<ub-color size="middle" type="color-11">11</ub-color>`,
            hhh114: `<ub-color size="large" type="color-11">11</ub-color>`,
        }
    }
}
</script>

<style scoped>
*{
    color:rgb(240, 136, 136);
    font-size:20px;
    font-weight: 800;
    margin-right:20px;
}
.line{
    width: 60vw;
    height: 2px;
    background-color: greenyellow;
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>