<template>
    <ub-ani size="mini" type="btn-1">1</ub-ani>
    <ub-ani size="small" type="btn-1">1</ub-ani>
    <ub-ani size="middle" type="btn-1">1</ub-ani>
    <ub-ani size="large" type="btn-1">1</ub-ani>
    <br><br>
    <pre>{{ ani11 }}</pre>
    <pre>{{ ani12 }}</pre>
    <pre>{{ ani13 }}</pre>
    <pre>{{ ani14 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-2">2</ub-ani>
    <ub-ani size="small" type="btn-2">2</ub-ani>
    <ub-ani size="middle" type="btn-2">2</ub-ani>
    <ub-ani size="large" type="btn-2">2</ub-ani>
    <br><br>
    <pre>{{ ani21 }}</pre>
    <pre>{{ ani22 }}</pre>
    <pre>{{ ani23 }}</pre>
    <pre>{{ ani24 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-3">3</ub-ani>
    <ub-ani size="small" type="btn-3">3</ub-ani>
    <ub-ani size="middle" type="btn-3">3</ub-ani>
    <ub-ani size="large" type="btn-3">3</ub-ani>
    <br><br>
    <pre>{{ ani31 }}</pre>
    <pre>{{ ani32 }}</pre>
    <pre>{{ ani33 }}</pre>
    <pre>{{ ani34 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-4">4</ub-ani>
    <ub-ani size="small" type="btn-4">4</ub-ani>
    <ub-ani size="middle" type="btn-4">4</ub-ani>
    <ub-ani size="large" type="btn-4">4</ub-ani>
    <br><br>
    <pre>{{ ani41 }}</pre>
    <pre>{{ ani42 }}</pre>
    <pre>{{ ani43 }}</pre>
    <pre>{{ ani44 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-5">5</ub-ani>
    <ub-ani size="small" type="btn-5">5</ub-ani>
    <ub-ani size="middle" type="btn-5">5</ub-ani>
    <ub-ani size="large" type="btn-5">5</ub-ani>
    <br><br>
    <pre>{{ ani51 }}</pre>
    <pre>{{ ani52 }}</pre>
    <pre>{{ ani53 }}</pre>
    <pre>{{ ani54 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-6">6</ub-ani>
    <ub-ani size="small" type="btn-6">6</ub-ani>
    <ub-ani size="middle" type="btn-6">6</ub-ani>
    <ub-ani size="large" type="btn-6">6</ub-ani>
    <br><br>
    <pre>{{ ani61 }}</pre>
    <pre>{{ ani62 }}</pre>
    <pre>{{ ani63 }}</pre>
    <pre>{{ ani64 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-7">7</ub-ani>
    <ub-ani size="small" type="btn-7">7</ub-ani>
    <ub-ani size="middle" type="btn-7">7</ub-ani>
    <ub-ani size="large" type="btn-7">7</ub-ani>
    <br><br>
    <pre>{{ ani71 }}</pre>
    <pre>{{ ani72 }}</pre>
    <pre>{{ ani73 }}</pre>
    <pre>{{ ani74 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-8">8</ub-ani>
    <ub-ani size="small" type="btn-8">8</ub-ani>
    <ub-ani size="middle" type="btn-8">8</ub-ani>
    <ub-ani size="large" type="btn-8">8</ub-ani>
    <br><br>
    <pre>{{ ani81 }}</pre>
    <pre>{{ ani82 }}</pre>
    <pre>{{ ani83 }}</pre>
    <pre>{{ ani84 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-9">9</ub-ani>
    <ub-ani size="small" type="btn-9">9</ub-ani>
    <ub-ani size="middle" type="btn-9">9</ub-ani>
    <ub-ani size="large" type="btn-9">9</ub-ani>
    <br><br>
    <pre>{{ ani91 }}</pre>
    <pre>{{ ani92 }}</pre>
    <pre>{{ ani93 }}</pre>
    <pre>{{ ani94 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-10">10</ub-ani>
    <ub-ani size="small" type="btn-10">10</ub-ani>
    <ub-ani size="middle" type="btn-10">10</ub-ani>
    <ub-ani size="large" type="btn-10">10</ub-ani>
    <br><br>
    <pre>{{ ani101 }}</pre>
    <pre>{{ ani102 }}</pre>
    <pre>{{ ani103 }}</pre>
    <pre>{{ ani104 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-11">11</ub-ani>
    <ub-ani size="small" type="btn-11">11</ub-ani>
    <ub-ani size="middle" type="btn-11">11</ub-ani>
    <ub-ani size="large" type="btn-11">11</ub-ani>
    <br><br>
    <pre>{{ ani111 }}</pre>
    <pre>{{ ani112 }}</pre>
    <pre>{{ ani113 }}</pre>
    <pre>{{ ani114 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-12">12</ub-ani>
    <ub-ani size="small" type="btn-12">12</ub-ani>
    <ub-ani size="middle" type="btn-12">12</ub-ani>
    <ub-ani size="large" type="btn-12">12</ub-ani>
    <br><br>
    <pre>{{ ani121 }}</pre>
    <pre>{{ ani122 }}</pre>
    <pre>{{ ani123 }}</pre>
    <pre>{{ ani124 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-13">13</ub-ani>
    <ub-ani size="small" type="btn-13">13</ub-ani>
    <ub-ani size="middle" type="btn-13">13</ub-ani>
    <ub-ani size="large" type="btn-13">13</ub-ani>
    <br><br>
    <pre>{{ ani131 }}</pre>
    <pre>{{ ani132 }}</pre>
    <pre>{{ ani133 }}</pre>
    <pre>{{ ani134 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-14">14</ub-ani>
    <ub-ani size="small" type="btn-14">14</ub-ani>
    <ub-ani size="middle" type="btn-14">14</ub-ani>
    <ub-ani size="large" type="btn-14">14</ub-ani>
    <br><br>
    <pre>{{ ani141 }}</pre>
    <pre>{{ ani142 }}</pre>
    <pre>{{ ani143 }}</pre>
    <pre>{{ ani144 }}</pre>

    <div class="line"></div>
    <ub-ani size="mini" type="btn-15">15</ub-ani>
    <ub-ani size="small" type="btn-15">15</ub-ani>
    <ub-ani size="middle" type="btn-15">15</ub-ani>
    <ub-ani size="large" type="btn-15">15</ub-ani>
    <br><br>
    <pre>{{ ani151 }}</pre>
    <pre>{{ ani152 }}</pre>
    <pre>{{ ani153 }}</pre>
    <pre>{{ ani154 }}</pre>

    <br><br>
    <i>the end.</i>
    <br><br><br><br><br>
</template>

<script>
export default {
    name: "AniShow",
    data() {
        return {
            ani11:`<ub-ani size="mini" type="btn-1">1</ub-ani>`,
            ani12:`<ub-ani size="small" type="btn-1">1</ub-ani>`,
            ani13:`<ub-ani size="middle" type="btn-1">1</ub-ani>`,
            ani14: `<ub-ani size="large" type="btn-1">1</ub-ani>`,

            ani21: `<ub-ani size="mini" type="btn-2">2</ub-ani>`,
            ani22: `<ub-ani size="small" type="btn-2">2</ub-ani>`,
            ani23: `<ub-ani size="middle" type="btn-2">2</ub-ani>`,
            ani24: `<ub-ani size="large" type="btn-2">2</ub-ani>`,

            ani31: `<ub-ani size="mini" type="btn-3">3</ub-ani>`,
            ani32: `<ub-ani size="small" type="btn-3">3</ub-ani>`,
            ani33: `<ub-ani size="middle" type="btn-3">3</ub-ani>`,
            ani34: `<ub-ani size="large" type="btn-3">3</ub-ani>`,

            ani41: `<ub-ani size="mini" type="btn-4">4</ub-ani>`,
            ani42: `<ub-ani size="small" type="btn-4">4</ub-ani>`,
            ani43: `<ub-ani size="middle" type="btn-4">4</ub-ani>`,
            ani44: `<ub-ani size="large" type="btn-4">4</ub-ani>`,

            ani51: `<ub-ani size="mini" type="btn-5">5</ub-ani>`,
            ani52: `<ub-ani size="small" type="btn-5">5</ub-ani>`,
            ani53: `<ub-ani size="middle" type="btn-5">5</ub-ani>`,
            ani54: `<ub-ani size="large" type="btn-5">5</ub-ani>`,

            ani61: `<ub-ani size="mini" type="btn-6">6</ub-ani>`,
            ani62: `<ub-ani size="small" type="btn-6">6</ub-ani>`,
            ani63: `<ub-ani size="middle" type="btn-6">6</ub-ani>`,
            ani64: `<ub-ani size="large" type="btn-6">6</ub-ani>`,

            ani71: `<ub-ani size="mini" type="btn-7">7</ub-ani>`,
            ani72: `<ub-ani size="small" type="btn-7">7</ub-ani>`,
            ani73: `<ub-ani size="middle" type="btn-7">7</ub-ani>`,
            ani74: `<ub-ani size="large" type="btn-7">7</ub-ani>`,

            ani81: `<ub-ani size="mini" type="btn-8">8</ub-ani>`,
            ani82: `<ub-ani size="small" type="btn-8">8</ub-ani>`,
            ani83: `<ub-ani size="middle" type="btn-8">8</ub-ani>`,
            ani84: `<ub-ani size="large" type="btn-8">8</ub-ani>`,

            ani91: `<ub-ani size="mini" type="btn-9">9</ub-ani>`,
            ani92: `<ub-ani size="small" type="btn-9">9</ub-ani>`,
            ani93: `<ub-ani size="middle" type="btn-9">9</ub-ani>`,
            ani94: `<ub-ani size="large" type="btn-9">9</ub-ani>`,

            ani101: `<ub-ani size="mini" type="btn-10">10</ub-ani>`,
            ani102: `<ub-ani size="small" type="btn-10">10</ub-ani>`,
            ani103: `<ub-ani size="middle" type="btn-10">10</ub-ani>`,
            ani104: `<ub-ani size="large" type="btn-10">10</ub-ani>`,

            ani111: `<ub-ani size="mini" type="btn-11">11</ub-ani>`,
            ani112: `<ub-ani size="small" type="btn-11">11</ub-ani>`,
            ani113: `<ub-ani size="middle" type="btn-11">11</ub-ani>`,
            ani114: `<ub-ani size="large" type="btn-11">11</ub-ani>`,

            ani121: `<ub-ani size="mini" type="btn-12">12</ub-ani>`,
            ani122: `<ub-ani size="small" type="btn-12">12</ub-ani>`,
            ani123: `<ub-ani size="middle" type="btn-12">12</ub-ani>`,
            ani124: `<ub-ani size="large" type="btn-12">12</ub-ani>`,

            ani131: `<ub-ani size="mini" type="btn-13">13</ub-ani>`,
            ani132: `<ub-ani size="small" type="btn-13">13</ub-ani>`,
            ani133: `<ub-ani size="middle" type="btn-13">13</ub-ani>`,
            ani134: `<ub-ani size="large" type="btn-13">13</ub-ani>`,

            ani141: `<ub-ani size="mini" type="btn-14">14</ub-ani>`,
            ani142: `<ub-ani size="small" type="btn-14">14</ub-ani>`,
            ani143: `<ub-ani size="middle" type="btn-14">14</ub-ani>`,
            ani144: `<ub-ani size="large" type="btn-14">14</ub-ani>`,

            ani151: `<ub-ani size="mini" type="btn-15">15</ub-ani>`,
            ani152: `<ub-ani size="small" type="btn-15">15</ub-ani>`,
            ani153: `<ub-ani size="middle" type="btn-15">15</ub-ani>`,
            ani154: `<ub-ani size="large" type="btn-15">15</ub-ani>`,
        }
    }
}
</script>

<style scoped>
*{
    color:rgb(240, 136, 136);
    font-weight: 800;
    font-size: 20px;
    margin-right:20px;
}
.line{
    width: 60vw;
    height: 2px;
    background-color: greenyellow;
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>