<template>
     <ub-black size="mini" type="red">RED</ub-black>
     <ub-black size="small" type="red">RED</ub-black>
     <ub-black size="middle" type="red">RED</ub-black>
     <ub-black size="large" type="red">RED</ub-black>
     <br><br>
     <pre>{{ blackRed1 }}</pre>
     <pre>{{ blackRed2 }}</pre>
     <pre>{{ blackRed3 }}</pre>
     <pre>{{ blackRed4 }}</pre>

     <div class="line"></div>
     <ub-black size="mini" type="green">GREEN</ub-black>
     <ub-black size="small" type="green">GREEN</ub-black>
     <ub-black size="middle" type="green">GREEN</ub-black>
     <ub-black size="large" type="green">GREEN</ub-black>
     <br><br>
     <pre>{{ blackGreen1 }}</pre>
     <pre>{{ blackGreen2 }}</pre>
     <pre>{{ blackGreen3 }}</pre>
     <pre>{{ blackGreen4 }}</pre>

     <div class="line"></div>
     <ub-black size="mini" type="blue">BLUE</ub-black>
     <ub-black size="small" type="blue">BLUE</ub-black>
     <ub-black size="middle" type="blue">BLUE</ub-black>
     <ub-black size="large" type="blue">BLUE</ub-black>
     <br><br>
     <pre>{{ blackBlue1 }}</pre>
     <pre>{{ blackBlue2 }}</pre>
     <pre>{{ blackBlue3 }}</pre>
     <pre>{{ blackBlue4 }}</pre>

     <br><br>
     <i>the end.</i>
     <br><br><br><br><br>
</template>

<script>
export default {
     name: "BlackShow",
     data() {
          return {
               blackRed1: `<ub-black size="mini" type="red">RED</ub-black>`,
               blackRed2: `<ub-black size="small" type="red">RED</ub-black>`,
               blackRed3: `<ub-black size="middle" type="red">RED</ub-black>`,
               blackRed4: `<ub-black size="large" type="red">RED</ub-black>`,

               blackGreen1: `<ub-black size="mini" type="green">GREEN</ub-black>`,
               blackGreen2: `<ub-black size="small" type="green">GREEN</ub-black>`,
               blackGreen3: `<ub-black size="middle" type="green">GREEN</ub-black>`,
               blackGreen4: `<ub-black size="large" type="green">GREEN</ub-black>`,

               blackBlue1: `<ub-black size="mini" type="blue">BLUE</ub-black>`,
               blackBlue2: `<ub-black size="small" type="blue">BLUE</ub-black>`,
               blackBlue3: `<ub-black size="middle" type="blue">BLUE</ub-black>`,
               blackBlue4: `<ub-black size="large" type="blue">BLUE</ub-black>`,
          }
     }
}
</script>

<style scoped>
*{
    color:rgb(240, 136, 136);
    font-weight: 800;
    margin-right:20px;
    font-size: 20px;
}
.line{
    width: 60vw;
    height: 2px;
    background-color: greenyellow;
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>