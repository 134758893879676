<template>
    <ub-light size="mini" type="btn-1">1</ub-light>
    <ub-light size="small" type="btn-1">1</ub-light>
    <ub-light size="middle" type="btn-1">1</ub-light>
    <ub-light size="large" type="btn-1">1</ub-light>
    <br><br>
    <pre>{{ light11 }}</pre>
    <pre>{{ light12 }}</pre>
    <pre>{{ light13 }}</pre>
    <pre>{{ light14 }}</pre>

    <div class="line"></div>
    <ub-light size="mini" type="btn-2">2</ub-light>
    <ub-light size="small" type="btn-2">2</ub-light>
    <ub-light size="middle" type="btn-2">2</ub-light>
    <ub-light size="large" type="btn-2">2</ub-light>
    <br><br>
    <pre>{{ light21 }}</pre>
    <pre>{{ light22 }}</pre>
    <pre>{{ light23 }}</pre>
    <pre>{{ light24 }}</pre>

    <div class="line"></div>
    <ub-light size="mini" type="btn-3">3</ub-light>
    <ub-light size="small" type="btn-3">3</ub-light>
    <ub-light size="middle" type="btn-3">3</ub-light>
    <ub-light size="large" type="btn-3">3</ub-light>
    <br><br>
    <pre>{{ light31 }}</pre>
    <pre>{{ light32 }}</pre>
    <pre>{{ light33 }}</pre>
    <pre>{{ light34 }}</pre>

    <div class="line"></div>
    <ub-light size="mini" type="btn-4">4</ub-light>
    <ub-light size="small" type="btn-4">4</ub-light>
    <ub-light size="middle" type="btn-4">4</ub-light>
    <ub-light size="large" type="btn-4">4</ub-light>
    <br><br>
    <pre>{{ light41 }}</pre>
    <pre>{{ light42 }}</pre>
    <pre>{{ light43 }}</pre>
    <pre>{{ light44 }}</pre>

    <div class="line"></div>
    <ub-light size="mini" type="btn-5">5</ub-light>
    <ub-light size="small" type="btn-5">5</ub-light>
    <ub-light size="middle" type="btn-5">5</ub-light>
    <ub-light size="large" type="btn-5">5</ub-light>
    <br><br>
    <pre>{{ light51 }}</pre>
    <pre>{{ light52 }}</pre>
    <pre>{{ light53 }}</pre>
    <pre>{{ light54 }}</pre>

    <br><br>
    <i>the end.</i>
    <br><br><br><br><br>
</template>

<script>
export default {
    name: "LightShow",
    data() {
        return {
            light11: `<ub-light size="mini" type="btn-1">1</ub-light>`,
            light12: `<ub-light size="small" type="btn-1">1</ub-light>`,
            light13: `<ub-light size="middle" type="btn-1">1</ub-light>`,
            light14: `<ub-light size="large" type="btn-1">1</ub-light>`,

            light21: `<ub-light size="mini" type="btn-2">2</ub-light>`,
            light22: `<ub-light size="small" type="btn-2">2</ub-light>`,
            light23: `<ub-light size="middle" type="btn-2">2</ub-light>`,
            light24: `<ub-light size="large" type="btn-2">2</ub-light>`,

            light31: `<ub-light size="mini" type="btn-3">3</ub-light>`,
            light32: `<ub-light size="small" type="btn-3">3</ub-light>`,
            light33: `<ub-light size="middle" type="btn-3">3</ub-light>`,
            light34: `<ub-light size="large" type="btn-3">3</ub-light>`,

            light41: `<ub-light size="mini" type="btn-4">4</ub-light>`,
            light42: `<ub-light size="small" type="btn-4">4</ub-light>`,
            light43: `<ub-light size="middle" type="btn-4">4</ub-light>`,
            light44: `<ub-light size="large" type="btn-4">4</ub-light>`,

            light51: `<ub-light size="mini" type="btn-5">5</ub-light>`,
            light52: `<ub-light size="small" type="btn-5">5</ub-light>`,
            light53: `<ub-light size="middle" type="btn-5">5</ub-light>`,
            light54: `<ub-light size="large" type="btn-5">5</ub-light>`,
        }
    }
}
</script>

<style scoped>
*{
    color:rgb(240, 136, 136);
    font-size: 20px;
    font-weight: 800;
    margin-right:20px;
}
.line{
    width: 60vw;
    height: 2px;
    background-color: greenyellow;
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>