 /* eslint-disable */

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import UltraButton from 'ultra-button'
import '../node_modules/ultra-button/ultra-button.css'
import App from './App.vue'

const app = createApp(App)
app.use(UltraButton);
app.use(ElementPlus)
app.mount('#app')
