<template>
  <el-tabs :tab-position="tabPosition" class="tabs">
    <el-tab-pane label="start" class="inside">
      <howToStart />
    </el-tab-pane>
    <el-tab-pane label="ub-color" class="inside">
      <pageStart />
      <color-show></color-show>
    </el-tab-pane>
    <el-tab-pane label="ub-light" class="inside">
      <pageStart />
      <light-show></light-show>
    </el-tab-pane>
    <el-tab-pane label="ub-ani" class="inside">
      <pageStart />
      <ani-show></ani-show>
    </el-tab-pane>
    <el-tab-pane label="ub-black" class="inside">
      <pageStart />
      <black-show></black-show>
    </el-tab-pane>
    <el-tab-pane label="ub-cyber" class="inside">
      <cyber-show></cyber-show>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
/* eslint-disable */
import colorShow from './components/color-show.vue';
import lightShow from './components/light-show.vue';
import aniShow from './components/ani-show.vue';
import blackShow from './components/black-show.vue';
import cyberShow from './components/cyber-show.vue';
import pageStart from './components/page-start.vue';
import howToStart from './components/how-to-start.vue';

export default {
  name: 'App',
  data() {
    return {
      tabPosition:"left",
    }
  },
  components: {
    colorShow,
    lightShow,
    aniShow,
    blackShow,
    cyberShow,
    pageStart,
    howToStart,
  }
}
</script>

<style scoped>
.tabs{
  margin-left:50px;
  margin-top: 100px;
}
.inside{
  padding-left: 50px;
}
</style>
