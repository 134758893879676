<template>
      <pre class="start">以下的按钮，鼠标悬浮到按钮上皆有效果。
The following buttons have an effect when hovering the mouse over them.
      </pre>
      <ub-cyber>CYBER</ub-cyber>
      <br><br>
      <pre>{{ cyber }}</pre>
</template>

<script>
export default {
      name: "CyberShow",
      data() {
            return {
                  cyber:`<ub-cyber>CYBER</ub-cyber>`
            }
      }
}
</script>

<style scoped>
*{
    color:rgb(240, 136, 136);
    font-weight: 800;
    font-size: 20px;
    margin-right:20px;
}
.start{
      font-size: 25px;
      font-weight: 800;
      color: black;
}
</style>