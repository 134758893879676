<template>
<pre class="title">Ultra Button</pre>
    <pre>1.

这个库的名字叫做ultra-button，本意是希望提供一些有意思的按钮样式。
The name of this project is ultra button, 
and the original intention is to provide some interesting button styles.
        


这个库创建于2023年8月17日晚。
当时的目的是练习，如何打包发布到npm。
目前短期内没有维护的计划，不过欢迎交流，右上角是GitHub仓库链接。

This project was created on the evening of August 17, 2023.
The purpose at that time was to practice how to package and publish to NPM.
There are currently no plans to continue maintenance in the short term, 
but welcome to communicate. The GitHub warehouse link is located in the upper right corner.

2.

如何开始：
How To Start:

    首先，创建一个vue项目
    First, create a Vue project
            <i class="code">
            npm i ultra-button
            </i>
    导入
    import<i class="code">
            import { createApp } from 'vue'
            import UltraButton from 'ultra-button'
            import '../node_modules/ultra-button/ultra-button.css'
            import App from './App.vue'

            const app = createApp(App)
            app.use(UltraButton);
            app.mount('#app')
            </i>
如果想继续看所有按钮，请继续阅读。  
If you want to continue with all buttons, please continue reading.          
    </pre>
</template>

<script>
export default {
    name:"HowToStart"
}
</script>

<style scoped>
*{
    font-size: 25px;
    font-weight: 800;
    color: rgb(236, 52, 15);
}
.title{
    font-size: 100px;
    color: coral;
}
.code{
    font-size: 30px;
    font-weight: 800;
    color: black;
}
</style>