<template>
    <pre>以下的按钮，鼠标悬浮到按钮上皆有效果。
The following buttons have an effect when hovering the mouse over them.


type属性通常为不同颜色，size属性有从小到大四个级别。
The type attribute is usually of different colors, 
while the size attribute has four levels from small to large.
    </pre>
    <div></div>
</template>

<script>
export default {
    name:"PageStart",
}
</script>

<style scoped>
*{
    font-size: 25px;
    font-weight: 800;
}
div{
    width: 30vw;
    height: 5px;
    background-color: rgb(248, 248, 0);
    margin-bottom: 50px;
}
</style>